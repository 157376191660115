.slide-menu {
  background: $brand-primary;
  padding: 0 20px;
  left: -70%;
  display: none;

  .dropdown-menu {
    display: block;
    position: initial;
    float: none;
    background: none;
    min-width: auto;
    z-index: inherit;
    font-size: inherit;
    border: none;
    border-radius: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 0 0;
    margin: 10px 0 0;
  }

  .dropdown-toggle {
    display: none;
  }

  .dropdown-item {
    padding: 0;
    clear: initial;
    font-weight: initial;
    line-height: inherit;
    white-space: inherit;
    width: auto;

    &:focus, &:hover {
      background-color: transparent;
    }

  }

  h2 {
    color: #fff;
    font-weight: normal;
  }

  .brand {
    padding: 22px 0;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,.25);
    img { margin-left: -20px; }
  }

  .slide-docs-nav > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      padding: 0 0 10px;
      margin: 25px 0 0px;
      > ul {
        border-top: 1px dashed rgba(0,0,0,.1);
        display: block;
        list-style: none;
        margin: 10px 0 0 0;
        padding: 10px 0 0 0;
        font-size: 14px;
      }
    }
  }
  .slide-main-nav {
    list-style: none;
    padding: 25px 0;
    margin: 0;
    border-bottom: 1px solid rgba(255,255,255,.1);
  }

  a {
    line-height: 1.5;
    color: rgba(255,255,255,.9);
    &:hover {
      color: #fff;
    }
  }
}

.docs .slide-main-nav .nav-docs { display: none; }

.wrap {
  position: relative;
}

.overlay {
  position: fixed;
  background: rgba(255,255,255, 0.75);
  width: 100%;
  height: 100%;
  display: none;
  z-index: 999999;
  -webkit-transition: all 100ms ease;
  -moz-transition: all 100ms ease;
  transition: all 100ms ease;

  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;

  cursor: pointer;
}

.scotch-is-showing .overlay {
  display: block;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
