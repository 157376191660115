@charset "utf-8";

@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700,200italic,400italic,700italic);

// Settings
@import "core/settings";

// Third party components
@import "components/bootstrap";
@import "components/prism";
@import "components/browsers";
@import "components/panels";
@import "components/nav";
@import "components/footer";
@import "components/sidebar";
@import "components/slide-menu";

// Core
@import "core/base";

// Content
@import "content/docs";
